import { useEffect, useRef, useState } from 'react';
import { Review } from '@sapientpro/sapientpro-data-models';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useAtomValue } from 'jotai';
import PaginationButton, { PaginationItem } from './PaginationButton';
import styles from './ReviewsList.module.scss';
import '@splidejs/react-splide/css';
import deviceScreenSize, { ScreenSize } from '../../../../store/deviceScreenSize';

interface IPaginationSliderProps {
  data: Review[];
  slider: Slider;
  activeIndex: number;
}

const PaginationSlider = ({ data, slider, activeIndex }: IPaginationSliderProps) => {
  const screenSize = useAtomValue(deviceScreenSize);
  const [paginationItems, setPaginationItems] = useState<PaginationItem[]>([]);
  const [activeItemIndex, setActiveItemIndex] = useState<number>(0);
  const sliderRef = useRef<Slider>(null);

  useEffect(() => {
    if (data.length) {
      const newPaginationButtons = data.map((item) => ({
        content: item?.industryTitle || item?.case?.industries?.[0]?.title,
        title: item?.case?.projectName || '',
      }));
      setPaginationItems(newPaginationButtons);
    }
  }, [data]);

  useEffect(() => {
    if (activeIndex !== activeItemIndex) {
      sliderRef.current?.slickGoTo(activeIndex);
    }
  }, [activeIndex]);

  useEffect(() => {
    if (slider) {
      sliderRef.current?.forceUpdate();
    }
  }, [slider]);

  const enableFocus = screenSize > ScreenSize.MOBILE;

  return (
    <Slider
      className={styles.paginationSlider}
      infinite
      arrows={false}
      centerMode
      dots={false}
      variableWidth
      focusOnSelect={enableFocus}
      asNavFor={slider}
      ref={sliderRef}
      afterChange={(index) => {
        setActiveItemIndex(index);
      }}
    >
      {paginationItems.map((item, index) => (
        <PaginationButton
          key={item.title}
          item={item}
          handleChange={() => {
            setActiveItemIndex(index);
          }}
          isActive={activeItemIndex === index}
        />
      ))}
    </Slider>
  );
};

export default PaginationSlider;
