import { Review, ReviewListView } from '@sapientpro/sapientpro-data-models';
import { useAtomValue } from 'jotai';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import { theme } from '../../../../store/theme';
import styles from './FullReview.module.scss';
import ReviewAuthor from '../ReviewAuthor';
import Img from '../../../../components/Img';
import CustomCursorWrapper from '../../../../components/CustomCursor/CustomCursorWrapper';

interface IFullReviewProps {
  className: string,
  review: Review,
  view: ReviewListView,
  activeIndex: number;
}

type VideoDataResponse = {
  etag: string;
  items: {
    id: string;
    kind: string;
    contentDetails: {
      duration: string;
      caption: string;
      definition: string;
      licensedContent: boolean;
      projection: string;
    },
  }[];
  kind: string;
};

const formatDuration = (durationString: string) => {
  let result = durationString;

  result = result.replace(/PT|S/g, '');
  result = result.replace(/H|M/g, ':');
  result = result.replace(/\b(\d)\b/g, '0$1');

  if (result.length === 2) result = `00:${result}`;

  return result;
};

const FullReview = ({
  className, review, view, activeIndex,
}: IFullReviewProps) => {
  const appTheme = useAtomValue(theme);
  const videoRef = useRef<HTMLIFrameElement>(null);
  const [videoDuration, setVideoDuration] = useState<string>('');
  const [showVideo, setShowVideo] = useState<boolean>(false);

  function controlVideo(controlFunction: string) {
    if (videoRef) {
      videoRef.current?.contentWindow?.postMessage(
        `{"event":"command","func":"${controlFunction}","args":""}`,
        '*',
      );
    }
  }

  useEffect(() => {
    const getDuration = async (link: string) => {
      const apiKey = process.env.NEXT_PUBLIC_YOUTUBE_API_KEY;
      const videoId = link.split('embed/')?.[1].split('?')?.[0];
      const url = `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&part=contentDetails&key=${apiKey}`;

      if (!apiKey || !videoId) return;
      try {
        const response = await fetch(url, {
          method: 'get',
        });
        const data = await response.json();

        const duration = (data as VideoDataResponse)?.items?.[0]?.contentDetails?.duration || '';
        const formattedDuration = formatDuration(duration);

        setVideoDuration(formattedDuration);
      } catch (error) {
        console.error('getDuration error', error);
      }
    };

    if (review.clipFeedbackLink) getDuration(review.clipFeedbackLink);
  }, [review]);

  useEffect(() => {
    controlVideo('stopVideo');
    setShowVideo(false);
  }, [activeIndex]);

  const showPreview = !showVideo && review.clipPreview?.url;

  return (
    <div
      className={classNames(styles.fullReview, styles[`${view}-view`], className)}
    >
      {showPreview && (
      <button
        type='button'
        onClick={() => {
          !!review.clipFeedbackLink && setShowVideo(true);
        }}
        className={styles.previewButton}
      >
        <CustomCursorWrapper
          cursorText='Play'
          view='light'
          className={styles.previewWrapper}
          disabled={!review.clipFeedbackLink}
        >
          <Img
            src={review?.clipPreview?.url}
            alt={review?.clipPreview?.alternativeText || 'review video preview'}
            className={styles.preview}
          />
          <ReviewAuthor
            avatar={review?.author?.avatar}
            name={review?.author?.name}
            position={review?.author?.position}
            className={styles.author}
            photoClassName={styles.author__avatar}
            nameClassName={styles.author__name}
            positionClassName={styles.author__position}
          />
          {!!review.clipFeedbackLink && (
          <div className={styles.previewOverlay}>
            <svg className={styles.playIcon}>
              <use
                href='media/play-icon.svg#play-icon'
                xlinkHref='media/play-icon.svg#play-icon'
              />
            </svg>

            {!!videoDuration && <span className={styles.videoDuration}>{videoDuration}</span>}
          </div>
          )}
        </CustomCursorWrapper>
      </button>
      )}
      {showVideo && (
        <div
          className={styles.video}
        >
          <iframe
            ref={videoRef}
            width='950'
            height='534'
            src={`${review.clipFeedbackLink}?rel=0&enablejsapi=1`}
            title={`${review.author.name} feedback`}
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            allowFullScreen
            onLoad={() => controlVideo('playVideo')}
          />
        </div>
      )}
      <div className={styles.reviewContent}>
        <div className={styles.reviewText}>
          <svg className={styles.reviewIcon}>
            <use
              href='media/quote-icon.svg#quote-icon'
              xlinkHref='media/quote-icon.svg#quote-icon'
            />
          </svg>
          <p>
            <span>
              {review.textFeedback}
            </span>
          </p>
        </div>
        <div className={styles.reviewFooter}>
          <ReviewAuthor
            avatar={review?.author?.avatar}
            name={review?.author?.name}
            position={review?.author?.position}
            className={classNames(styles.author, styles[`${view}-view`])}
            photoClassName={styles.author__avatar}
            nameClassName={styles.author__name}
            positionClassName={styles.author__position}
          />
          {!!review.clutchLink && (
          <Link
            className={styles.author__clutch}
            href={review.clutchLink}
            target='_blank'
          >
            <Img
              src={`/media/clutch_${appTheme}.svg`}
              alt='clutch'
              width={43}
              height={12}
              className={styles.logo}
            />
            Based
          </Link>
          )}
        </div>
        <div className={styles.blur} />
      </div>
    </div>
  );
};

export default React.memo(FullReview);
