import { IReviewVideo, ViewValues } from '@sapientpro/sapientpro-data-models';
import {
  Options, Splide, SplideSlide, SplideTrack,
} from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import classnames from 'classnames';
import { useAtomValue } from 'jotai';
import React, {
  RefObject, useRef, useState,
} from 'react';
import Img from '../../../../components/Img';
import clientDeviceSize, { ScreenSize } from '../../../../store/deviceScreenSize';
import { theme } from '../../../../store/theme';
import ReviewsVideo from './ReviewsVideo';

import styles from './ReviewsVideos.module.scss';

const ReviewsVideos = ({ data, view }: { data: IReviewVideo[]; view: ViewValues }) => {
  const appTheme = useAtomValue(theme);
  const deviceSize = useAtomValue<ScreenSize>(clientDeviceSize);

  let sliderGap;
  switch (deviceSize) {
    case ScreenSize.MOBILE:
      sliderGap = 10;
      break;
    case ScreenSize.DESKTOP_HUGE:
      sliderGap = 60;
      break;
    default:
      sliderGap = 24;
  }

  const videoRefs = data.map(() => useRef<HTMLIFrameElement>(null));
  const [showVideos, setShowVideos] = useState<boolean[]>(Array(data.length).fill(false));
  const reviewsSliderRef = useRef<Splide>(null);

  function controlVideo(controlFunction: string, ref?: RefObject<HTMLIFrameElement>) {
    if (ref) {
      ref.current?.contentWindow?.postMessage(
        `{"event":"command","func":"${controlFunction}","args":""}`,
        '*',
      );
    } else {
      videoRefs.map(videoRef => videoRef.current?.contentWindow?.postMessage(
        `{"event":"command","func":"${controlFunction}","args":""}`,
        '*',
      ));
    }
  }

  const reviewSliderOptions: Options = {
    focus: 'center',
    pagination: false,
    type: 'loop',
    padding: '-50px',
    autoWidth: true,
    gap: sliderGap,
  };

  return (
    <div className={classnames(styles.reviews, styles[`${view}-view`], {
      [styles.reviews_single]: data.length === 1,
      container: data.length === 1,
    })}
    >
      {data.length > 1 ? (
        <Splide
          hasTrack={false}
          options={reviewSliderOptions}
          className={styles.slider}
          ref={reviewsSliderRef}
        >
          <SplideTrack className={styles.track}>
            {data.map((review, index) => (
              <SplideSlide key={review.id}>
                <ReviewsVideo
                  className={styles.review}
                  review={review}
                  ref={videoRefs[index]} // eslint-disable-next-line react/jsx-no-bind
                  controlVideo={controlVideo}
                  showVideo={showVideos[index]}
                  setShowVideo={(value) => {
                    const newShowVideos = [...showVideos];
                    newShowVideos[index] = value;
                    setShowVideos(newShowVideos);
                  }}
                  view={view}
                />
              </SplideSlide>
            ))}
          </SplideTrack>
          <div className={classnames(styles.navigation, 'splide__arrows')}>
            <button
              onClick={() => {
                Array(data.length).fill(false);
                controlVideo('stopVideo');
              }}
              type='button'
              className={classnames('splide__arrow splide__arrow--prev', styles.arrow, styles.arrow_prev)}
            >
              <Img
                src={`/media/slider-arrow-right-${appTheme}.svg`}
                alt='slider arrow right'
              />
            </button>
            <button
              onClick={() => {
                setShowVideos(Array(data.length).fill(false));
                controlVideo('stopVideo');
              }}
              type='button'
              className={classnames('splide__arrow splide__arrow--next', styles.arrow, styles.arrow_next)}
            >
              <Img
                src={`/media/slider-arrow-right-${appTheme}.svg`}
                alt='slider arrow right'
              />
            </button>
          </div>
        </Splide>
      ) : (
        <ReviewsVideo
          className={classnames(styles.review, styles.review_single)}
          review={data[0]}
          ref={videoRefs[0]} // eslint-disable-next-line react/jsx-no-bind
          controlVideo={controlVideo}
          showVideo={showVideos[0]}
          setShowVideo={(value) => {
            const newShowVideos = [...showVideos];
            newShowVideos[0] = value;
            setShowVideos(newShowVideos);
          }}
          view={view}
        />
      )}
    </div>
  );
};

export default ReviewsVideos;
