import {
  ComponentProps,
  ReactNode, useEffect, useRef, useState,
} from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import styles from './CustomCursor.module.scss';
import CustomCursor from './CustomCursor';

interface ICustomCursorWrapperProps {
  children: ReactNode;
  cursorText: string;
  view?: ComponentProps<typeof CustomCursor>['view'];
  className?: string;
  disabled?: boolean;
}

const CustomCursorWrapper = ({
  children, cursorText, view, className, disabled,
}: ICustomCursorWrapperProps) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const cursorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const moveCursor = (e: MouseEvent) => {
      const mouseY = e.clientY;
      const mouseX = e.clientX;
      const cursor = cursorRef.current;

      if (cursor) {
        cursor.style.top = `${mouseY - 48}px`;
        cursor.style.left = `${mouseX - 48}px`;
      }
    };

    wrapperRef.current?.addEventListener('mousemove', moveCursor);
    wrapperRef.current?.addEventListener('mouseover', () => setIsActive(true));
    wrapperRef.current?.addEventListener('mouseleave', () => setIsActive(false));

    return () => {
      wrapperRef.current?.removeEventListener('mousemove', moveCursor);
      wrapperRef.current?.removeEventListener('mouseover', () => setIsActive(true));
      wrapperRef.current?.removeEventListener('mouseleave', () => setIsActive(false));
    };
  }, []);

  const showCursor = isActive && !disabled;
  return (
    <div
      className={classNames(styles.customCursorWrapper, className, {
        [styles.disabled]: disabled,
      })}
      ref={wrapperRef}
    >
      {children}
      {
       showCursor && createPortal(
         <CustomCursor
           text={cursorText}
           view={view}
           ref={cursorRef}
         />,
         document.body,
       )
      }
    </div>
  );
};

export default CustomCursorWrapper;
