'use client';

import classNames from 'classnames';
import { forwardRef } from 'react';
import styles from './CustomCursor.module.scss';

interface IProps {
  text: string;
  view?: 'light' | 'dark';
}

const CustomCursor = forwardRef<HTMLDivElement, IProps>(({ text, view = 'dark' }, ref) => (
  <div
    className={classNames(styles.customCursor, styles[view])}
    aria-disabled
    ref={ref}
  >
    {text}
  </div>
));

export default CustomCursor;
