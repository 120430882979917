import { ReviewList, ReviewListView } from '@sapientpro/sapientpro-data-models';
import classNames from 'classnames';
import {
  useEffect, useRef, useState,
} from 'react';
import Slider from 'react-slick';
import { useAtomValue } from 'jotai';
import styles from './ReviewsList.module.scss';
import PaginationSlider from './PaginationSlider';
import FullReview from './FullReview';
import '@splidejs/react-splide/css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import deviceScreenSize, { ScreenSize } from '../../../../store/deviceScreenSize';

interface IReviewsListProps {
  data: ReviewList;
  view: ReviewListView;
}

const ReviewsList = ({ data, view }: IReviewsListProps) => {
  const { reviews } = data;
  const screenSize = useAtomValue(deviceScreenSize);
  const reviewsSliderRef = useRef<Slider>(null);
  const [slider, setSlider] = useState<Slider | null>(null);
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);

  useEffect(() => {
    if (reviewsSliderRef.current) {
      setSlider(reviewsSliderRef.current);
    }
  }, [reviewsSliderRef.current]);

  const enableSwipe = screenSize < ScreenSize.TABLET_PORTRAIT;

  return (
    <div className={classNames(styles.reviewsList, styles[`${view}-view`])}>
      <PaginationSlider
        data={reviews}
        slider={slider as Slider}
        activeIndex={activeSlideIndex}
      />
      <Slider
        infinite
        slidesToShow={1}
        draggable={false}
        swipe={enableSwipe}
        className={styles.reviewsSlider}
        ref={reviewsSliderRef}
        afterChange={(index) => {
          setActiveSlideIndex(index);
        }}
        onSwipe={(dir) => {
          switch (dir) {
            case ('right'): {
              setActiveSlideIndex(activeSlideIndex - 1);
              break;
            }
            case ('left'): {
              setActiveSlideIndex(activeSlideIndex + 1);
              break;
            }
            default: setActiveSlideIndex(activeSlideIndex + 1);
          }
        }}
      >
        {reviews.map((review) => (
          <FullReview
            key={review?.case?.id || review.clutchLink}
            className={styles.review}
            review={review}
            view={view}
            activeIndex={activeSlideIndex}
          />
        ))}
      </Slider>
    </div>
  );
};

export default ReviewsList;
